import { HackleClient } from "./hackle";
import { EventProps } from "../types";

export type { EventProps };

declare global {
  interface Window {
    dataLayer: any[];
    dablena: any;
    fbq: any;
    wcs_add: any;
    _nasa: any;
    wcs: any;
    wcs_do: any;
    hackleClient: any;
    hackleUser: any;
    kakaoPixel: any;
  }
}

export const customEvent = (event: EventProps) => {
  if (process.env.NODE_ENV === "development") {
    console.log("event", event);
  }

  if (event) {
    NewGTMCustomEvent(event);
    TriggerHackleCustomEvent(event);
  }
};

const parseEventPropsToClickEventName = (event: EventProps) => {
  if (event.action) {
    return event.action;
  } else {
    const clickEventName = `click-${event.path}-${event.trigger_and_target}`;
    return clickEventName;
  }
};

export const NewGTMCustomEvent = (event: EventProps) => {
  const eventName = parseEventPropsToClickEventName(event);
  const gtmEventObject = { event: eventName };

  if (window.dataLayer) {
    window.dataLayer.push({ ...gtmEventObject });
  }
};

export const TriggerHackleCustomEvent = (event: EventProps) => {
  const eventName = parseEventPropsToClickEventName(event);
  const hackleEventObject = {
    key: eventName,
  };

  HackleClient.track(hackleEventObject, HackleClient.getUser());
};
