import styled from "@emotion/styled";
import { Circle } from "@chakra-ui/react";
import { ChangeEvent, HTMLAttributes } from "react";
import { colors } from "goi_common";

import { HStack } from "./HStack";

interface BaseProps {
  index?: number;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checkedColor: string;
  borderType?: "primary" | "circle";
  label?: string;
}

type Props = BaseProps &
  Omit<HTMLAttributes<HTMLInputElement>, keyof BaseProps>;

export const Checkbox = ({
  index,
  checked,
  label,
  checkedColor,
  borderType = "primary",
  ...rest
}: Props) => {
  const id = `checkbox-${index ?? 0}`;

  return (
    <>
      <HiddenInput type="checkbox" checked={checked} id={id} {...rest} />
      <StyledLabel htmlFor={id}>
        <HStack gap={7} alignItems="center">
          {borderType === "primary" && (
            <div
              css={{
                width: "26px",
                height: "26px",
                padding: "4px",
                border: `1px solid ${colors.gray300}`,
                borderRadius: "5px",
                background: colors.white,
              }}
            >
              <div
                css={{
                  width: "100%",
                  height: "100%",
                  background: checked ? checkedColor : colors.white,
                  borderRadius: "3px",
                }}
              />
            </div>
          )}
          {borderType === "circle" && (
            <Circle
              css={{
                width: "26px",
                height: "26px",
                padding: "4px",
                border: `1px solid ${colors.gray300}`,
                background: colors.white,
              }}
            >
              <Circle
                css={{
                  width: "100%",
                  height: "100%",
                  background: checked ? checkedColor : colors.white,
                }}
              />
            </Circle>
          )}
          <div className="subtitle_14_sb black">{label}</div>
        </HStack>
      </StyledLabel>
    </>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  width: fit-content;
`;
