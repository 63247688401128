import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  ModalContentProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { customEvent, EventProps } from "goi_common";

export interface FooterItemProps {
  text: ReactNode;
  onClick: () => void;
  width?: string;
  disable?: boolean;
  event?: EventProps;
  background?: string;
}

export interface HeaderItemProps {
  title?: string;
  onClick?: () => void;
  closeEvent: {
    newGtm: EventProps;
  };
}

export interface OverlayModalFooterProps {
  item: FooterItemProps[];
}

export interface OverlayModalHeaderProps {
  item: HeaderItemProps;
}

export interface OverlayModalProps {
  body: ReactNode;
}

export function OverlayModal({
  isOpen,
  onClose,
  closeEvent,
  overlayModalContent,
  modalWrapProps,
  modalContentStyle,
  closeOnOverlayClick,
}: {
  isOpen: boolean;
  onClose: () => void;
  closeEvent?: EventProps;
  overlayModalContent: ReactNode;
  modalWrapProps?: Omit<ModalProps, "children" | "isOpen" | "onClose">;
  modalContentStyle?: ModalContentProps;
  closeOnOverlayClick?: boolean;
}) {
  return (
    <Modal
      {...modalWrapProps}
      isOpen={isOpen}
      onClose={() => {
        if (closeEvent) customEvent(closeEvent);
        onClose();
      }}
      closeOnOverlayClick={closeOnOverlayClick || false}
      scrollBehavior="inside"
      isCentered={true}
      autoFocus={false}
      closeOnEsc={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay width="100%" height="100%" />
      <ModalContent {...modalContentStyle} width="100%" maxHeight="80%">
        <ModalBody padding="0px">{overlayModalContent}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
