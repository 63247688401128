import { ReactNode } from "react";
import { colors } from "../../styles";
import { VStack, HStack, HLine } from "../index";
import { OverlayModal } from "../OverlayModal";

export const AlertDialog = ({
  isOpen,
  content,
  buttonText,
  onClose,
  onClickButton,
}: {
  isOpen: boolean;
  content: ReactNode;
  buttonText: string;
  onClose: () => void;
  onClickButton?: () => void;
}) => {
  return (
    <OverlayModal
      isOpen={isOpen}
      onClose={onClose}
      modalContentStyle={{
        maxWidth: "260px",
      }}
      overlayModalContent={
        <VStack background={colors.white} borderRadius="10px" overflow="hidden">
          <HStack
            className="body1 gray900"
            padding="28px 10px 32px 10px"
            justifyContent="center"
            css={{
              whiteSpace: "pre-wrap",
              textAlign: "center",
            }}
          >
            {content}
          </HStack>
          <HLine color={colors.gray100} />
          <HStack>
            <button
              className="body_18_m"
              onClick={onClickButton}
              css={{
                width: "100%",
                padding: "16px 0",
                background: colors.white,
                color: colors.green400,
              }}
            >
              {buttonText}
            </button>
          </HStack>
        </VStack>
      }
    />
  );
};
