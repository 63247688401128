import {
  AlertDialog,
  ColorIcon,
  colors,
  ConfirmDialog,
  GoiLogo,
  HStack,
  Spacing,
  useAlertDialog,
  useConfirmDialog,
} from "goi_common";
import { useRouter } from "next/router";

import { MOBILE_WIDTH } from "@/constants/layout";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { alertDialog, closeAlertDialog } = useAlertDialog();
  const { confirmDialog, closeConfirmDialog } = useConfirmDialog();

  const router = useRouter();

  const isHiddenHeader =
    router.asPath.includes("/login") ||
    router.asPath.includes("/bankaccount") ||
    router.asPath.includes("share/visitor") ||
    router.asPath.includes("flower");

  const isShowBackHeader =
    router.asPath.includes("bugo/update/binso") ||
    router.asPath.includes("bugo/update/goin") ||
    router.asPath.includes("bugo/update/sangju") ||
    router.asPath.includes("bugo/share/invite-sangju") ||
    router.asPath.includes("bugo/share/choice");

  return (
    <>
      {isHiddenHeader ? (
        children
      ) : (
        <>
          <header
            css={{
              position: "fixed",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              maxWidth: `${MOBILE_WIDTH}px`,
              padding: "20px 18px",
              background: colors.white,
              zIndex: 1000,
              borderBottom: `1px solid ${colors.gray100}`,
            }}
          >
            {isShowBackHeader ? (
              <BugoBackHeaderContent />
            ) : (
              <BugoCommonHeaderContent />
            )}
          </header>
          <Spacing gap={66} />
          {children}
        </>
      )}

      <AlertDialog {...alertDialog} onClose={closeAlertDialog} />
      <ConfirmDialog {...confirmDialog} onClose={closeConfirmDialog} />
    </>
  );
};

const BugoCommonHeaderContent = () => {
  const router = useRouter();

  return (
    <HStack
      gap="11px"
      onClick={() => {
        router.push("/");
      }}
    >
      <GoiLogo />
      <div
        className="subtitle_18_b"
        css={{
          color: colors.black,
          fontFamily: "Nanum Myeongjo",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
        }}
      >
        모바일 부고장
      </div>
    </HStack>
  );
};

const BugoBackHeaderContent = () => {
  const router = useRouter();

  return (
    <HStack
      gap="2px"
      onClick={() => {
        router.back();
      }}
    >
      <ColorIcon
        name="icon-arrow-right-small-mono"
        color={colors.gray900}
        css={{
          transform: "rotate(180deg)",
        }}
      />
      <nav className="subtitle_18_b">돌아가기</nav>
    </HStack>
  );
};
