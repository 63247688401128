import { CSSProperties, PropsWithChildren } from "react";
import { VStack } from "goi_common";

import { MOBILE_WIDTH } from "@/constants/layout";

export const MobileLayout = ({
  children,
  wrapCSSProps,
}: PropsWithChildren<{ wrapCSSProps?: CSSProperties }>) => {
  return (
    <VStack width="100%" alignItems="center">
      <VStack
        width="100%"
        css={{
          maxWidth: `${MOBILE_WIDTH}px`,
          ...wrapCSSProps,
        }}
      >
        {children}
      </VStack>
    </VStack>
  );
};
