import Hotjar from "@hotjar/browser";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useInitHotjarExcludeVisitor = () => {
  const router = useRouter();

  function initHotjar() {
    const HOTJAR_VERSION = 6;
    // const isProduct = process.env.NEXT_PUBLIC_IS_PRODUCTION === "true";
    const hotjarSiteId = Number(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID);

    // if (isProduct) {
    Hotjar.init(hotjarSiteId, HOTJAR_VERSION);
    // }
  }

  /** hotjar init 조문객 제외 */
  useEffect(() => {
    if (!router.isReady) return;

    const 조문객용_urls = [
      "/bankaccount",
      "/login",
      "/share/visitor/[sangjuKey]",
    ];

    if (조문객용_urls.includes(router.pathname)) {
      console.log("조문객용 페이지");
      return;
    }

    initHotjar();
  }, [router.asPath, router.isReady]);

  return {
    initHotjar,
  };
};
