import { Modal, ModalContent, VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export interface BaseModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const BaseModal = ({
  children,
  onClose,
  isOpen,
}: PropsWithChildren<BaseModalProps>) => {
  return (
    <Modal
      onClose={onClose}
      size="full"
      isOpen={isOpen}
      allowPinchZoom
      autoFocus={false}
    >
      <ModalContent>
        <VStack
          css={{
            alignItems: "center",
          }}
        >
          {children}
        </VStack>
      </ModalContent>
    </Modal>
  );
};
