export const cdnImagePreload = (urls: string[]) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = `${process.env.NEXT_PUBLIC_CDN_URL}${url}`;
  });
};

export const imagePreload = (urls: string[]) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};
