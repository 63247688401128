import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { getCdnImageSrc } from "../../utils";

interface IconProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  className?: string;
  id?: string;
  fill?: string;
}

export function Icon({
  src,
  width,
  height,
  alt,
  onClick,
  className,
  id,
  fill,
}: IconProps) {
  return (
    <IconContainer w={width} h={height} className={className} id={id}>
      <Image
        src={getCdnImageSrc(`icons${src}`)}
        alt={alt}
        width={width}
        height={height}
        onClick={onClick}
        id={id}
        style={{
          fill: fill,
          pointerEvents: "none",
        }}
      />
    </IconContainer>
  );
}

const IconContainer = styled.div<{ w?: number; h?: number }>`
  ${({ w }) =>
    w &&
    css`
      min-width: ${w}px;
      width: ${w}px;
    `}
  ${({ h }) =>
    h &&
    css`
      min-height: ${h}px;
      height: ${h}px;
    `}
`;

const Image = styled("img")``;
