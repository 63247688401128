import { atom, useAtom } from "jotai";
import { ComponentProps, ReactNode, useCallback } from "react";

import { ConfirmDialog } from "../components/Dialog/ConfirmDialog";

const confirmDialogState = atom<{
  isOpen: boolean;
  content: ReactNode;
  cancelButtonText: string;
  confirmButtonText: string;
  onClickCancelButton: () => void;
  onClickConfirmButton: () => void;
}>({
  isOpen: false,
  content: "",
  cancelButtonText: "",
  confirmButtonText: "",
  onClickCancelButton: () => {},
  onClickConfirmButton: () => {},
});

export function useConfirmDialog() {
  const [confirmDialog, setConfirmDialog] = useAtom(confirmDialogState);

  const openConfirmDialog = useCallback(
    ({
      content,
      cancelButtonText,
      confirmButtonText,
      onClickCancelButton,
      onClickConfirmButton,
    }: Pick<
      ComponentProps<typeof ConfirmDialog>,
      | "content"
      | "cancelButtonText"
      | "confirmButtonText"
      | "onClickCancelButton"
      | "onClickConfirmButton"
    >) => {
      setConfirmDialog({
        isOpen: true,
        content,
        cancelButtonText,
        confirmButtonText,
        onClickCancelButton: onClickCancelButton || closeConfirmDialog,
        onClickConfirmButton: onClickConfirmButton || closeConfirmDialog,
      });
    },
    [setConfirmDialog]
  );

  const closeConfirmDialog = useCallback(() => {
    setConfirmDialog((confirmDialog) => ({
      ...confirmDialog,
      isOpen: false,
    }));
  }, [setConfirmDialog]);

  return { confirmDialog, openConfirmDialog, closeConfirmDialog };
}
