import { atom, useAtom } from "jotai";
import { ComponentProps, ReactNode, useCallback } from "react";
import { AlertDialog } from "../components/Dialog/AlertDialog";

const alertDialogState = atom<{
  isOpen: boolean;
  content: ReactNode;
  buttonText: string;
  onClickButton: () => void;
}>({
  isOpen: false,
  content: "",
  buttonText: "",
  onClickButton: () => {},
});

export function useAlertDialog() {
  const [alertDialog, setAlertDialog] = useAtom(alertDialogState);

  const openAlertDialog = useCallback(
    ({
      content,
      buttonText,
      onClickButton,
    }: Pick<
      ComponentProps<typeof AlertDialog>,
      "content" | "buttonText" | "onClickButton"
    >) => {
      setAlertDialog({
        isOpen: true,
        content,
        buttonText,
        onClickButton: onClickButton || closeAlertDialog,
      });
    },
    [setAlertDialog]
  );

  const closeAlertDialog = useCallback(() => {
    setAlertDialog((alertDialog) => ({
      ...alertDialog,
      isOpen: false,
    }));
  }, [setAlertDialog]);

  return { alertDialog, openAlertDialog, closeAlertDialog };
}
