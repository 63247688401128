export const parsingPhoneNumber = (num: string) => {
  if (num.length > 13) {
    return num.slice(0, 13);
  }

  return num
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(-{1,2})$/g, "");
};
