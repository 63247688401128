import { Global, css } from "@emotion/react";
import { theme, ThemeType } from "goi_common";

const makeVariables = (theme: ThemeType) => {
  const themeVariables: string[] = [];
  Object.entries(theme).forEach((section) => {
    const [, sectionValue] = section;

    Object.entries(sectionValue).forEach(([key, value]) => {
      themeVariables.push(`--${key}: ${value}`);
    });
  });

  return themeVariables;
};

const style = css`
  :root {
    ${makeVariables(theme)}
  }

  html,
  body {
    font-family: "Pretendard Variable", -apple-system, BlinkMacSystemFont,
      system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
  }

  .show-scrollbar {
    ::-webkit-scrollbar {
      display: block;
    }
  }

  -webkit-tap-highlight-color: transparent;

  .stop-dragging {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
`;

export const GlobalStyle = () => {
  return <Global styles={style} />;
};
