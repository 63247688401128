import { MOBILE_WIDTH } from "@/constants/layout";
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const BottomSheet = ({
  isOpen,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay
        css={{
          backgroundColor: "background: rgba(0, 0, 0, 0.30)",
        }}
      />
      <DrawerContent
        css={{
          width: "100%",
          maxWidth: `${MOBILE_WIDTH}px !important`,
          margin: "0 auto",
          borderRadius: "30px 30px 0px 0px",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};
